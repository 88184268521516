import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

export const createTranslateMultiLoader = (paths: string[], prefix = 'messages') => (http: HttpClient) => {
  const resources = paths.map((path) => ({ prefix: `${path}/${prefix}.`, suffix: '.json' }));

  return new MultiTranslateHttpLoader(http, resources);
};

export class MultiTranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient, public resources: { prefix: string; suffix: string }[] = []) {}

  /**
   * Gets the translations from the server
   * @param lang
   * @returns {any}
   */
  public getTranslation(lang: string): any {
    return forkJoin(
      this.resources.map((config) => this.http.get(`${config.prefix}${lang}${config.suffix}`))
    ).pipe(
      map((response) => response.reduce((a, b) => Object.assign(a, b)))
    );
  }
}
