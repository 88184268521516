import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@app/services/base-service.service';
import urlJoin from 'url-join';
import { BuyPromise } from '@app/models/buy-promise';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

interface ShoppingCartItem {
	publication_id: number,
	container_id: number,
	quantity: number
}

interface ShoppingCartModification {
	"items": ShoppingCartItem[]|[],
	"last_modified_item": ShoppingCartItem|null
}

@Injectable()
export class CrmService extends BaseService<ShoppingCartModification> {

	/**
	 * CRM Internal API Communication Service
	*/

	static api_url_bitrix_update_cart = '/api/crm/shopping-cart/';
	urlBitrixUpdateCart: string;

	static getHeaders(headers?: { [header: string]: string | string[] }): HttpHeaders {
		if (!headers) headers = {};
		headers['Content-Type'] = 'application/json';
		return new HttpHeaders(headers);
  }
	
	private cartChanged: Subject<ShoppingCartModification> = new Subject<ShoppingCartModification>();

	constructor(http: HttpClient) {
		super(http, CrmService.api_url_bitrix_update_cart);
		this.urlBitrixUpdateCart = urlJoin((<any>window).env.endPointBackend, CrmService.api_url_bitrix_update_cart);
		this.cartChanged
			.pipe(debounceTime(5000)) // 5 sec delay to avoid multiple calls
			.subscribe((params: ShoppingCartModification) => {
				this.http.put<ShoppingCartModification>(
					this.urlBitrixUpdateCart, 
					params,
					{headers: CrmService.getHeaders()}
				).subscribe((ok) => {}, (error) => {
					let msgError;
					const errDict = error.error;
					for (const key of Object.keys(errDict)) {
						const value = errDict[key];
						if (key === 'items') {
							for (const item of value) {
								for (const k of Object.keys(item)) {
									msgError = item[k];
									console.log(msgError);
								}
							}
						}
					}
				});
			});
	}

	updateShoppingCartStatus(actualCart:BuyPromise[], lastModifiedItem:BuyPromise|null) {
		let cartItemsForBitrix: BuyPromise[] = actualCart ? actualCart : [];

		const cartModification:ShoppingCartModification = {
			"items": 
				(cartItemsForBitrix && cartItemsForBitrix.length > 0) ? this.getShoppingCartItemsForBitrix(cartItemsForBitrix) : [],
			"last_modified_item": 
				lastModifiedItem ? this.getShoppingCartItemForBitrix(lastModifiedItem) : null
		}
		this.cartChanged.next(cartModification);
  }

	getShoppingCartItemsForBitrix(buyPromise: BuyPromise[]): ShoppingCartItem[] {
		let cartItems: ShoppingCartItem[] = [];
		buyPromise.forEach((item: BuyPromise) => {
			cartItems.push(this.getShoppingCartItemForBitrix(item));
		});
		return cartItems;
	}

	getShoppingCartItemForBitrix(buyPromise: BuyPromise): ShoppingCartItem {
		return {
			publication_id: buyPromise.publication_id ? 
											buyPromise.publication_id : buyPromise.product.publication_impo,
			container_id: buyPromise.product.id,
			quantity: buyPromise.quantity
		}
	}

}