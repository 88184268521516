<div class="container-404">
  <mat-toolbar>
    <mat-toolbar-row class="toolbar-row" color="primary">
      <div
        (click)="goToMainPage()"
        class="u-pt-x3 u-pr-x3@xs logo__wrapper"
      >
        <picture class="u-cursor--pointer">
          <source
            media="(max-width: 599px)"
            srcset="assets/images/logo75x75.png"
          >
          <img
            class="logo"
            alt="logo"
            src="assets/images/isologo_ceg.png"
          />
        </picture>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="content-404">
    <div class="data-404">
      <div class="mat-display-1 color-secondary u-mh-x6 u-mb-x4" i18n>
        Ups! It seems that this page does not exist.
      </div>
      <div class="link-main-page" (click)="goToMainPage()" i18n>
        Go to the main page
      </div>
    </div>
  </div>
</div>

