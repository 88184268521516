import { Injectable } from '@angular/core';
import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@app/services/auth/auth.service';
import { tap } from 'rxjs/internal/operators';
import { Router } from '@angular/router';

const AUTH_HEADER = 'Authorization';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,
              public router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authHeader = this.authService.getAuthorizationHeader();
    let { headers } = req;
    if (authHeader) {
      headers = headers.set(AUTH_HEADER, authHeader);
    }
    const authReq = req.clone({ headers });
    return next.handle(authReq).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {

        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403) {
            this.authService.getMenus();
            this.redirect();
          }
        }
      })
    );
  }

  redirect() {
    this.authService.Menu.subscribe((res) => {
      if (res.indexOf('purchase') >= 0) {
        this.router.navigate(['intranet', 'impo']);
      } else if (res.indexOf('catalog') >= 0) {
        this.router.navigate(['intranet', 'catalog']);
      } else if (res.indexOf('dispatch_note_management') >= 0) {
        this.router.navigate(['logistic-operator', 'dispatch-note-management']);
      } else {
        this.router.navigate(['intranet', 'report', 'account-status']);
      }
    });
  }
}
