import { SettingsPipe } from '@app/pipes/settings/settings.pipe';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IssueTypeIconPipe } from '@app/pipes/issues/issue-type-icon.pipe';
import { IssueCategoryIconPipe } from '@app/pipes/issues/issue-category-icon.pipe';
import { IssueCategoryTypeNamePipe } from '@app/pipes/issues/issue-category-type-name.pipe';
import { countries } from '@app/global-settings.data';
import { translateChildSettings } from '@app/utils/translate-child-settings/translate-child-settings';
import { createTranslateMultiLoader } from '@app/utils/translate-multi-loader/translate-multi-loader';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { IssueCategoryDescriptionPipe } from './issues/issue-category-description.pipe';
import { IssueCategoryNamePipe } from './issues/issue-category-name.pipe';
import { HighlightPipe } from './highlight/highlight.pipe';
import { FileSizePipe } from './file-size/file-size.pipe';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(translateChildSettings(
      countries[localStorage.getItem('country_code') || 'ARG'],
      createTranslateMultiLoader([
        './assets/locale/pipes'
      ]),
      TranslateMessageFormatCompiler
    ))
  ],
  declarations: [
    SettingsPipe,
    HighlightPipe,
    FileSizePipe,
    IssueTypeIconPipe,
    IssueCategoryIconPipe,
    IssueCategoryTypeNamePipe,
    IssueCategoryNamePipe,
    IssueCategoryDescriptionPipe
  ],
  exports: [
    SettingsPipe,
    HighlightPipe,
    FileSizePipe,
    IssueTypeIconPipe,
    IssueCategoryIconPipe,
    IssueCategoryTypeNamePipe,
    IssueCategoryNamePipe,
    IssueCategoryDescriptionPipe
  ]
})
export class MainPipeModule { }
