import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Guards
import { LogisticOperatorGuard } from '@app/auth/guards/logistic-operator/logistic-operator.guard';
import { CustomerGuard } from '@app/auth/guards/customer/customer.guard';
import { AuthGuard } from '@app/auth/guards/auth/auth.guard';

// Components
import { NotFoundComponent } from '@app/public/not-found/not-found.component';

const routes: Routes = [
  {
    path: 'intranet',
    canActivate: [AuthGuard, CustomerGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./public/public.module').then(m => m.PublicModule)
      }
    ]
  },
  {
    path: 'logistic-operator',
    canActivate: [AuthGuard, LogisticOperatorGuard],
    loadChildren: () =>
      import('./logistic-operator/logistic-operator.module').then(
        m => m.LogisticOperatorModule
      )
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'prospection',
    loadChildren: () =>
      import('./prospection/prospection.module').then(m => m.ProspectionModule)
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'intranet'
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
