import { Container } from './container';

export class BuyPromise {
  id: number;

  product_id: number;

  product: Container;

  purchase_order: number;

  quantity: number;

  original_quantity: number;

  company: number;

  publication_id: number;

  publication_title: string;
}
