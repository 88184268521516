import {
  of as observableOf, Observable, Subject, throwError, BehaviorSubject
} from 'rxjs';
import * as Raven from 'raven-js';
import * as jwt_decode from 'jwt-decode';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { map, tap } from 'rxjs/operators';
import { RequestAuth, ResponseAuth, ValidateToken } from '@app/models/auth';
import { User, AvailableCompanySettings } from '@app/models/user';
import { Company, AutoImputePayments } from '@app/models/company';
import { countries } from '@app/global-settings.data';
import { HotjarService } from '@app/services/hotjar/hotjar.service';
import urlJoin from 'url-join';

export const TOKEN_KEY = 'jwt';
export const TOKEN_REFRESH_LIMIT = 'refresh_limit';
export const USER_KEY = 'user';
export const COMPANY_TYPE_KEY = 'company_type';
export const COUNTRY_CODE_KEY = 'country_code';
export const HOME_KEY = 'home';
export const SHOPPING_CART_KEY = 'shopping_cart';
export const LAST_LOGIN_KEY = 'last_login';
export const DEFAULT_COMPANY_TYPE = 'customer';
const AUTH_PREFIX = 'JWT';

@Injectable()
export class AuthService {
  loginUrl = '/api/token-auth/';

  refreshUrl = '/api/token-refresh/';

  checkUrl = '/api/token-verify/';

  restorePasswordUrl = '/api/profile/forgot-password/';

  validatePasswordTokenUrl = '/api/profile/validate-token/';

  resetPasswordUrl = '/api/profile/reset-password/';

  activateUserUrl = '/api/profile/activate/';

  resendConfirmEmailUrl = '/api/profile/confirm-email/';

  menusUrl = '/api/menu/';

  availableCompanySettingsUrl = '/api/available-company-settings';

  autoImputePayments = '/api/auto-impute-payments/';

  subjectUser: Subject<User> = new Subject();

  subjectMenu: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  subjectCountryCode: Subject<string> = new Subject();

  lastLogin: Subject<string> = new Subject();

  get currentUser(): Observable<User> {
    return this.subjectUser.asObservable();
  }

  get Menu(): Observable<string[]> {
    return this.subjectMenu.asObservable();
  }

  get countryCode(): Observable<string> {
    return this.subjectCountryCode.asObservable();
  }

  constructor(private http: HttpClient, private hotjarService: HotjarService) {
  }

  login(requestAuth: RequestAuth, remember: boolean): Observable<HttpResponse<ResponseAuth>> {
    const loginUrl = urlJoin((<any>window).env.endPointBackend, this.loginUrl);
    return this.http
      .post(loginUrl, { username: requestAuth.username, password: requestAuth.password }, { observe: 'response' })
      .pipe(
        tap((response: HttpResponse<ResponseAuth>) => {
          this.processTokenResponse(response, remember);
        })
      );
  }

  logout(): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(SHOPPING_CART_KEY);
    sessionStorage.removeItem(TOKEN_KEY);
    this.setSentryContext();
    this.setHotjarContext();
  }

  processTokenResponse(response: HttpResponse<ResponseAuth>, remember: boolean = null) {
    if (Boolean(response.body) && Boolean(response.body.token)) {
      this.subjectUser.next(<User>response.body.user);
      this.subjectCountryCode.next(<string>response.body.country_code);
      if (!isNullOrUndefined(remember)) {
        this.setToken(response.body.token, remember);
      }
      this.setUser(<User>response.body.user);
      this.setTokenRefreshLimit(<number>response.body.refresh_limit);
      this.setCompanyType(<string>response.body.company_type);
      this.setCountryCode(<string>response.body.country_code);
      this.setLastLogin(<string>response.body.last_login);
      this.setHome(<string>response.body.url_redirect);
      this.setSentryContext();
      this.setHotjarContext();
      if ((<any>window).env.i18n) {
        const originalLocale = window.location.pathname.split('/')[1];
        const locale = `${countries[response.body.country_code]}`;
        if (locale !== originalLocale) {
          let redirectUrl = response.body.url_redirect;
          let { port } = window.location;
          if (port) {
            port = `:${port}`;
          }
          redirectUrl = `${window.location.protocol}//${window.location.hostname}${port}/${locale}/#${redirectUrl}`;
          window.open(redirectUrl, '_self');
        }
      }
    }
  }

  setToken(token: string, remember: boolean): void {
    if (remember) {
      sessionStorage.removeItem(TOKEN_KEY);
      localStorage.setItem(TOKEN_KEY, token);
    } else {
      localStorage.removeItem(TOKEN_KEY);
      sessionStorage.setItem(TOKEN_KEY, token);
    }
  }

  setUser(user: User): void {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
    this.subjectUser.next(user);
  }

  setCompanyType(company_type: string): void {
    localStorage.setItem(COMPANY_TYPE_KEY, company_type);
  }

  setTokenRefreshLimit(refresh_limit: number): void {
    localStorage.setItem(TOKEN_REFRESH_LIMIT, refresh_limit.toString());
  }

  public getTokenRefreshLimit(): number {
    return +localStorage.getItem(TOKEN_REFRESH_LIMIT);
  }

  setCountryCode(country_code: string): void {
    localStorage.setItem(COUNTRY_CODE_KEY, country_code);
  }

  setHome(url: string): void {
    localStorage.setItem(HOME_KEY, url);
  }

  getHome(): string {
    return localStorage.getItem(HOME_KEY);
  }

  setLastLogin(last_login: string): void {
    localStorage.setItem(LAST_LOGIN_KEY, last_login);
  }

  getLastLogin(): string {
    return localStorage.getItem(LAST_LOGIN_KEY);
  }

  setSentryContext(): void {
    const user = this.getUser();
    Raven.setUserContext({
      email: user ? user.email : null
    });

    const company: Company = user ? user.company : null;
    Raven.setExtraContext({
      comapny_name: company ? company.name : null,
      company_id: company ? company.id : null
    });
  }

  setHotjarContext(): void {
    const user = this.getUser();
    const company: Company = user ? user.company : null;
    this.hotjarService.setIdentity(user ? user.id : null, {
      user_email: user ? user.email : null,
      company_id: company ? company.id : null,
      company_name: company ? company.name : null
    });
  }

  updateUser(user: User): void {
    const currentUser = this.getUser();
    currentUser.first_name = user.first_name;
    currentUser.last_name = user.last_name;
    currentUser.email = user.email;
    this.subjectUser.next(currentUser);
    localStorage.setItem(USER_KEY, JSON.stringify(currentUser));
  }

  public getToken(): [string, boolean] {
    let token = localStorage.getItem(TOKEN_KEY);
    let remember = true;
    if (!token) {
      token = sessionStorage.getItem(TOKEN_KEY);
      remember = false;
    }
    return [token, remember];
  }

  isLoggedIn(): Observable<boolean> {
    const [token, remember]: [string, boolean] = this.getToken();
    if (token) {
      this.refreshToken(token, remember);
      return this.tokenVerify(token);
    }
    return throwError(false);
  }

  isStaff(): Observable<boolean> {
    const user: User = this.getUser();
    if (isNullOrUndefined(user) || !user.is_staff) {
      return throwError(false);
    }
    return observableOf(true);
  }

  refreshToken(token: string, remember): void {
    if (token) {
      const refresh_limit = this.getTokenRefreshLimit() || 157248000; // 5 years
      const decoded = jwt_decode(token);
      const { exp } = decoded;
      const { orig_iat } = decoded;
      const refresh_token = 604800; // 7 days
      // In the code below, we will check the token against these conditions: IF it is expiring in 7 day (604.800 second)
      // AND it is not reaching its lifespan (refresh_limit — refresh_token)
      if (exp - Date.now() / 1000 < refresh_token && Date.now() / 1000 - orig_iat < refresh_limit - refresh_token) {
        this.refreshTokenAndProcessResponse(token, remember).subscribe();
      }
    }
  }

  refreshTokenAndProcessResponse(token, remember): Observable<any> {
    const refreshUrl = urlJoin((<any>window).env.endPointBackend, this.refreshUrl);
    return this.http
      .post(refreshUrl, { token }, { observe: 'response' })
      .pipe(
        tap((response: HttpResponse<ResponseAuth>) => {
          this.processTokenResponse(response, remember);
        })
      );
  }

  tokenVerify(token: string, forceServerValidation = false): Observable<boolean> {
    // this is just for checking token consistency with the server
    const checkUrl = urlJoin((<any>window).env.endPointBackend, this.checkUrl);
    if (token) {
      const decoded = jwt_decode(token);
      const { exp } = decoded; // token expiration date
      const { orig_iat } = decoded; // token emitted date
      // we verify that the difference between the current time and the token issuance date does not exceed a time limit (forceVerifyTokenTime). Finally, we check that the token has not expired.
      if (
        !forceServerValidation
        && ((Date.now() / 1000) - orig_iat) < (<any>window).env.forceVerifyTokenTime
        && exp - (Date.now() / 1000) > 0
      ) {
        return observableOf(true);
      }
      return this.http.post(checkUrl, { token }, { observe: 'response' }).pipe(
        tap((response: HttpResponse<ResponseAuth>) => {
          this.processTokenResponse(response);
        }),
        map((response: HttpResponse<ResponseAuth>) => Boolean(response))
      );
    }
    return observableOf(null);
  }

  public getUser(): User | null {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    return user;
  }

  getAvailableCompanySettings(): Observable<AvailableCompanySettings> {
    const url = urlJoin((<any>window).env.endPointBackend, this.availableCompanySettingsUrl);
    const dictHeaders = {};
    const headers = new HttpHeaders(dictHeaders);
    return this.http.get<AvailableCompanySettings>(url, { headers });
  }
  
  getAutoImputePayments(): Observable<AutoImputePayments> {
    const url = urlJoin((<any>window).env.endPointBackend, this.autoImputePayments);
    const dictHeaders = {};
    const headers = new HttpHeaders(dictHeaders);
    return this.http.get<AutoImputePayments>(url, { headers });
  }

  public getCountryCode(): string {
    return localStorage.getItem(COUNTRY_CODE_KEY) || DEFAULT_COMPANY_TYPE;
  }

  public getCompanyType(): string {
    return localStorage.getItem(COMPANY_TYPE_KEY) || DEFAULT_COMPANY_TYPE;
  }

  public checkCompany(companyType): boolean {
    const company_type = this.getCompanyType();
    return company_type === companyType;
  }

  getMenus(): void {
    const url = urlJoin((<any>window).env.endPointBackend, this.menusUrl);
    const dictHeaders = { Authorization: null };
    dictHeaders.Authorization = this.getAuthorizationHeader();
    const headers = new HttpHeaders(dictHeaders);
    this.http
      .get<string[]>(url, {
        headers
      })
      .subscribe((menu: string[]) => {
        this.subjectMenu.next(menu);
        this.validateMenuWithCachedUser(menu);
      });
  }

  public getAuthorizationHeader(): string {
    let auth_header = '';
    const token: string = this.getToken()[0];
    if (!isNullOrUndefined(token) && token) {
      auth_header = `${AUTH_PREFIX} ${token}`;
      return auth_header;
    }
    return null;
  }

  restorePassword(email: string) {
    const restorePasswordUrl = urlJoin((<any>window).env.endPointBackend, this.restorePasswordUrl);
    return this.http.post(restorePasswordUrl, { email });
  }

  validateToken(userId: number, token: string) {
    const validateTokenUrl = urlJoin((<any>window).env.endPointBackend, this.validatePasswordTokenUrl, `${userId}`, `${token}`);
    return this.http.get<ValidateToken>(validateTokenUrl);
  }

  resetPassword(userId: number, token: string, password: string) {
    const resetPasswordUrl = urlJoin((<any>window).env.endPointBackend, this.resetPasswordUrl, `${userId}`, `${token}`, `/`);
    return this.http.post(resetPasswordUrl, { password });
  }

  activateUser(activationKey: string) {
    const activateUserUrl = urlJoin((<any>window).env.endPointBackend, this.activateUserUrl, activationKey);
    return this.http.get(activateUserUrl);
  }

  resendConfirmEmail(email: string) {
    const resendConfirmEmailUrl = urlJoin((<any>window).env.endPointBackend, this.resendConfirmEmailUrl, email);
    return this.http.get(resendConfirmEmailUrl);
  }

  /**
   * This workaround is used to validate the menu in order to update the cache that´s only updated on login
   * At the moment if the menu has more than one(1) item, it´s mean that the lead was approved
   * If actual user.is_prospect is true, it means that the user was approved and the cache was not yet updated
   * @param menu 
   */
  validateMenuWithCachedUser(menu: string[]): void {
    let user = this.getUser();
    const [token, remember]: [string, boolean] = this.getToken();
    if (menu.length > 1 && user && user.is_prospect === true) {
      // Refresh token and update cache
      this.refreshTokenAndProcessResponse(token, remember).subscribe();
    }
  }
  
}
