import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router
} from '@angular/router';
import { AuthService } from '@app/services/auth/auth.service';

@Injectable()
export class CustomerGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const authorized = this.authService.checkCompany('customer');
    if (!authorized) {
      const home = this.authService.getHome();
      this.router.navigateByUrl(home);
    }
    return observableOf(authorized);
  }
}
