import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, GuardsCheckEnd
} from '@angular/router';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@app/services/auth/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const { url } = state;
    const { router } = this;
    this.router.events.subscribe((event) => {
      if (event instanceof GuardsCheckEnd && event.url === '/') {
        const home = localStorage.getItem('home') || '/intranet';
        router.navigate([home]);
      }
    });
    return this.checkLogin(url);
  }

  checkLogin(url: string): Observable<boolean> {
    return this.authService.isLoggedIn()
      .pipe(
        catchError((err) => {
          // Navigate to the login page and Store the attempted URL for redirecting
          this.router.navigate(['auth', 'login'], { queryParams: { returnUrl: url } });
          return observableOf(false);
        })
      );
  }
}
