import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseResponse } from '@app/models/base-response';
import urlJoin from 'url-join';

export interface ParamConfig {
  limit?: number;
  offset?: number;
  search?: string;
  ordering?: string;
}

export class BaseService<T> {
  constructor(public http: HttpClient, private apiURL: string) {
    this.apiURL = urlJoin((<any>window).env.endPointBackend, apiURL);
  }

  static getHeaders(headers?: { [header: string]: string | string[] }) {
    if (!headers) {
      headers = {};
    }

    headers['Content-Type'] = 'application/json';
    return new HttpHeaders(headers);
  }

  /**
   * Method to get objects from back-end api
   * @param config
   * @returns {Observable<BaseResponse<T>>}
   */
  getCollection(config: ParamConfig = {}): Observable<BaseResponse<T>> {
    let params = new HttpParams();
    function objectKeys(obj) {
      return Object.keys(obj);
    }

    for (const key of objectKeys(config)) {
      if (config[key]) {
        if (!(config[key] instanceof Array)) {
          params = params.append(key, config[key].toString());
        } else {
          for (const value of config[key]) {
            if (value) { params = params.append(key, value.toString()); }
          }
        }
      }
    }

    return this.http.get<BaseResponse<T>>(this.apiURL, {
      params,
      headers: BaseService.getHeaders()
    }).pipe(
      map((res) => res)
    );
  }

  /**
   * Method to get a single object from back-end api
   * @param {id} id of the object to get
   * @returns {Observable<T>}
   */
  get(id: number): Observable<T> {
    const url = `${this.apiURL}${id}/`;
    return this.http.get<T>(url, { headers: BaseService.getHeaders() });
  }

  /**
   * Method to get a single object from back-end api
   * @param {id} id of the object to get
   * @param {tailPath} tailPath rest of url without start /
   * @returns {Observable<T>}
   */
  getCustom(id: number, tailPath: string): Observable<T> {
    const url = `${this.apiURL}${id}/${tailPath}/`;
    return this.http.get<T>(url, { headers: BaseService.getHeaders() });
  }

  /**
   * Given an object, this function makes a request to create a new object if no id
   * is provided. If the object has a fild id, it requests for an update of the object.
   *
   * @param {Object} object object to be created or updated.
   * @returns {Observable<T>}
   */
  post(object: T): Observable<T> {
    let url = this.apiURL;
    if (!isNullOrUndefined((<any>object).id)) {
      url = `${this.apiURL}${(<any>object).id}/`;
      return this.http.put<T>(url, object, { headers: BaseService.getHeaders() });
    }
    return this.http.post<T>(url, object, { headers: BaseService.getHeaders() });
  }

  /**
   * Method to update a single object from back-end api
   *
   * @param {Object} object to be updated.
   * @returns {Observable<T>}
   */
  patch(object: T): Observable<T> {
    const url = `${this.apiURL}${(<any>object).id}/`;
    return this.http.patch<T>(url, object, { headers: BaseService.getHeaders() });
  }
}
