import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export declare type SnackBarMessage = 'SUCCESS' | 'ERROR' | 'WARNING' | 'INFO';

const SnackBarMessageDict = {
  SUCCESS: {
    class: 'success-alert'
  },
  ERROR: {
    class: 'error-alert'
  },
  WARNING: {
    class: 'warning-alert'
  },
  INFO: {
    class: 'info-alert'
  }
};

@Injectable()
export class SnackBarMessageService {
  constructor(public snackBar: MatSnackBar) { }

  openSnackBar(message: string, messageType: SnackBarMessage, duration: number = null) {
    this.snackBar.open(message, 'x', {
      duration,
      panelClass: SnackBarMessageDict[messageType].class
    });
  }

  closeSnackBar() {
    this.snackBar.dismiss();
  }
}
