import { of as observableOf, Observable, Subject, combineLatest } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { BuyPromise } from '@app/models/buy-promise';
import { SnackBarMessageService } from '@app/services/snack-bar-message/snack-bar-message.service';
import { AuthService } from '@app/services/auth/auth.service';
import { DPOConfirmation, DraftOrder, ShoppingCart } from '@app/models/shopping-cart';
import { StorageService } from '@app/services/storage/storage.service';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Costing, Range } from '@app/models/costing';
import { Container } from '@app/models/container';
import { GoogleTagManagerService } from '../google-tag-manager/google-tag-manager.service';
import urlJoin from 'url-join';
import { CrmService } from '../crm/crm.service';

@Injectable()
export class ShoppingCartService {
  public api_url = '/api/shopping-cart/';

  public draft_order_api = '/api/draft-purchase-order/';

  private draft_order_unconfirmed_api = 'has-any-unconfirmed/';

  private url: string;

  private draft_order_url: string;

  subject: Subject<BuyPromise[]> = new Subject();

  subjectHasChange: Subject<boolean> = new Subject();

  subjectHasError: Subject<boolean> = new Subject();

  needInitialize: Subject<boolean> = new Subject();

  _buyPromises: BuyPromise[] = [];

  originalBuyPromises: BuyPromise[] = [];

  private lastModifiedBuyPromise: BuyPromise;

  _hasChange: boolean;

  minPurchase: number;

  hasReceivedMerchandise: boolean;

  hasInitialCart: boolean = false;

  draftOrder: boolean;

  isDraftOrder = false;

  readonly = false;

  overstock: Observable<number[]> = observableOf([]);

  get buyPromises(): Observable<BuyPromise[]> {
    return this.subject.asObservable();
  }

  // Method used by Toolbar Component
  get hasChange(): Observable<boolean> {
    return this.subjectHasChange.asObservable();
  }

  get hasError(): Observable<boolean> {
    return this.subjectHasError.asObservable();
  }

  constructor(private http: HttpClient,
              private translate: TranslateService,
              public snackBar: SnackBarMessageService,
              private authService: AuthService,
              public storageService: StorageService,
              public gtmService: GoogleTagManagerService,
              private crmService: CrmService,
              private route: ActivatedRoute) {
    this.url = urlJoin((<any>window).env.endPointBackend, this.api_url);
    this.draft_order_url = urlJoin((<any>window).env.endPointBackend, this.draft_order_api);
    let needInitialize = true;
    this.route.queryParams.subscribe((params) => {
      const dpo_id = params.dpo;
      const { token } = params;
      if (dpo_id && token) {
        this.getDraftOrder(dpo_id, token);
        needInitialize = false;
        this.isDraftOrder = true;
        this.readonly = true;
      }
    });
    combineLatest([this.authService.Menu, this.authService.currentUser]).subscribe(([menu, user]) => {
      if (user && !user.is_prospect && menu && menu.indexOf('purchase') >= 0) {
        this.getInitialCart(needInitialize);
      }
    });
    this.authService.subjectUser.next(this.authService.getUser());
    this.authService.getMenus();

    this.storageService.changes.subscribe((res: {key: string; value: any}) => {
      if (res.key === 'shopping_cart') {
        this._buyPromises = res.value;
      }
    });
  }

  static getHeaders(headers?: { [header: string]: string | string[] }): HttpHeaders {
    if (!headers) {
      headers = {};
    }

    headers['Content-Type'] = 'application/json';
    return new HttpHeaders(headers);
  }

  getInitialCart(needInitialize = true): void {
    if (this.hasInitialCart) return
    this.hasInitialCart = true;
    this.http.get<ShoppingCart>(this.url, { headers: ShoppingCartService.getHeaders() })
      .subscribe((res) => {
        if (needInitialize) {
          if (res.items) {
            this._buyPromises = <BuyPromise[]>res.items;
            this.originalBuyPromises = <BuyPromise[]>res.items.map((x) => ({ ...x }));
          }
        }
        this.subject.next(this._buyPromises);
        this.minPurchase = res.min_purchase;
        this.hasReceivedMerchandise = res.has_received_merchandise;
        this.draftOrder = res.can_draft_purchase_order;
      }
      );
  }

  getOrderUpdated(): BuyPromise[] {
    const orderUpdated: BuyPromise[] = [];
    for (const bp of this._buyPromises) {
      if (bp.quantity > 0) {
        const bpNew = new BuyPromise();
        bpNew.publication_id = bp.publication_id ? bp.publication_id : bp.product.publication_impo;
        bpNew.product_id = bp.product.id;
        bpNew.quantity = bp.quantity;
        orderUpdated.push(bpNew);
      }
    }
    return orderUpdated;
  }

  updateShoppingCart(captchaToken: string, acceptedTermsAndConditions: boolean): void {
    const user = this.authService.getUser();
    const draft_order_id = this.route.snapshot.queryParams.dpo;
    const shoppingCart: ShoppingCart = new ShoppingCart();
    shoppingCart.captcha_token = captchaToken;
    shoppingCart.accepted_terms_and_conditions = acceptedTermsAndConditions;
    if (user && user.company) {
      shoppingCart.company_id = user.company.id;
    }
    if (draft_order_id) {
      shoppingCart.draft_order_id = +draft_order_id;
    }
    shoppingCart.items = this.getOrderUpdated();
    this.gtmService.registerConfirmCartEvent(this.getTotalPrice(), shoppingCart.items.length);
    this.http.post<ShoppingCart>(this.url, shoppingCart, { headers: ShoppingCartService.getHeaders() })
      .subscribe((res) => {
        this.snackBar.openSnackBar(
          this.translate.instant('shopping-cart__order-recieved'),
          'SUCCESS',
          8000
        );
        this._buyPromises = <BuyPromise[]>res.items; // save your data
        this.originalBuyPromises = <BuyPromise[]>res.items.map((x) => ({ ...x }));
        this.subjectHasChange.next(false);
        this.subject.next(this._buyPromises);
        // The value of readonly is set to false, since when it comes from a draft purchase order, it is not possible to modify the units.
        // After a correct confirmation you must be able to modify the units.
        this.readonly = false;
      }, (error) => {
        let msgError = this.translate.instant('shopping-cart__order-not-processed');
        const errDict = error.error;
        for (const key of Object.keys(errDict)) {
          const value = errDict[key];
          if (key === 'items') {
            for (const item of value) {
              for (const k of Object.keys(item)) {
                msgError = item[k];
              }
            }
          } else if (['captcha_token', 'accepted_terms_and_conditions', 'company_cant_buy'].includes(key)) {
            msgError = value;
          }
        }
        this.snackBar.openSnackBar(
          msgError,
          'ERROR',
          8000);
      }
      );
  }

  createDraftOrder(captchaToken: string): void {
    const user = this.authService.getUser();
    const draftOrder = new DraftOrder();
    if (user && user.company) {
      draftOrder.company_id = user.company.id;
    }
    draftOrder.captcha_token = captchaToken;
    draftOrder.items = this.getOrderUpdated();
    this.http.post<DraftOrder>(this.draft_order_url, draftOrder, { headers: ShoppingCartService.getHeaders() })
      .subscribe((res) => {
        this.snackBar.openSnackBar(
          this.translate.instant('shopping-cart__order-draft-created'), 'SUCCESS'
        );
        // Reset shopping cart after create draft order
        this.flush();
      },
      (error) => {
        let msgError = this.translate.instant('shopping-cart__order-not-processed');
        const errDict = error.error;
        for (const key of Object.keys(errDict)) {
          const value = errDict[key];
          if (key === 'items') {
            for (const item of value) {
              for (const k of Object.keys(item)) {
                msgError = item[k];
              }
            }
          } else if (['captcha_token', 'accepted_terms_and_conditions'].includes(key)) {
            msgError = value;
          }
        }
        this.snackBar.openSnackBar(
          msgError,
          'ERROR',
          8000);
      });
  }

  checkIfHasChanges(): void {
    this._hasChange = false;
    if (this.originalBuyPromises) {
      if (this.originalBuyPromises.length !== this._buyPromises.length) {
        this._hasChange = true;
      }
      for (const originBp of this.originalBuyPromises) {
        const index: number = this._buyPromises.findIndex((bp) => bp.product.id === originBp.product.id);
        if (index !== -1 && this._buyPromises[index].quantity !== originBp.quantity) {
          this._hasChange = true;
        } else if (index === -1) {
          this._hasChange = true;
        }
      }
    }
    this.subjectHasChange.next(this._hasChange);
  }

  /**
   * Add a product to the shopping cart
   * or update the quantity if the product already exists
   * @param buyPromise 
   * @param setOriginal 
   */
  addOrUpdate(buyPromise: BuyPromise, setOriginal = false): void {
    const index: number = this._buyPromises.findIndex((bp) => bp.product.id === buyPromise.product.id);
    if (index !== -1) {
      this._buyPromises[index].quantity = buyPromise.quantity;
      if (setOriginal && this._buyPromises[index].original_quantity !== buyPromise.quantity) {
        this.setLastModifiedBuyPromise(buyPromise);
        this._buyPromises[index].original_quantity = buyPromise.quantity;
        this.crmService.updateShoppingCartStatus(this.getNonZeroBuyPromises(), this.getLastModifiedBuyPromise());
        localStorage.setItem('shopping_cart', JSON.stringify(this._buyPromises));
      } else {
        this._buyPromises[index].original_quantity = buyPromise.original_quantity;
      }
    } else {
      this._buyPromises.push(buyPromise);
    }
    this.checkIfHasChanges();
    this.subject.next({ ...this._buyPromises });
  }

  getOriginalQuantity(id: number) {
    if (this.originalBuyPromises) {
      const bp = this.originalBuyPromises.find((x) => x.product.id === id);
      return bp?.quantity ?? 0;
    }
    return 0;
  }

  /**
   * Fill shopping cart with saved draft order
   * @param id of draft order to get
   * @param token to validate if order is valid
   */
  getDraftOrder(id: number, token: string): void {
    const get_url = `${(<any>window).env.endPointBackend}${this.draft_order_api}?dpo=${id}&token=${token}`;
    this.http.get<DraftOrder>(get_url, { headers: ShoppingCartService.getHeaders() })
      .subscribe(
        (res) => {
          for (const bp of <BuyPromise[]>res.items) {
            this.addOrUpdate(bp);
          }
        }, (error) => {
          this._buyPromises = [];
          this.snackBar.openSnackBar(error.error.message, 'ERROR', 8000);
        }
      );
  }

  /**
   * @description
   * Remove all items from shopping cart. Use it carefully
   */
  flush(): void {
    this._buyPromises = [];
  }

  /**
   * @description
   * Return true if shopping cart has buy promises else false
   */
  hasBuyPromises(): boolean {
    return Boolean(this._buyPromises.length);
  }

  /** Check if there is a product in overstock.
   *  In that case we must ask for confirmation
   */
  InOverstock(): Observable<boolean> {
    return this.overstock.pipe(
      map((list) => list.length !== 0)
    );
  }

  /** Add container to the list of containers with overstock */
  setContainerInOverstock(container_id: number): void {
    this.overstock.subscribe((list) => {
      const index = list.indexOf(container_id);
      if (index === -1) {
        list.push(container_id);
      }
    });
  }

  /** Remove container from list of overstock */
  removeContainerOverstock(container_id: number): void {
    this.overstock.subscribe((list) => {
      const index = list.indexOf(container_id);
      if (index >= 0) {
        list.splice(index, 1);
      }
    });
  }

  hasUnconfirmedDraftPurchaseOrder(): Observable<DPOConfirmation> {
    const dpo_confirmation_url = this.draft_order_url + this.draft_order_unconfirmed_api;
    return this.http.get<DPOConfirmation>(dpo_confirmation_url,
      { headers: ShoppingCartService.getHeaders() });
  }

  /**
   * Get BuyPromises with quantities other than zero.
   * @returns {BuyPromise[]}
   */
  getNonZeroBuyPromises():BuyPromise[] {
    return this._buyPromises.filter((x) => x.original_quantity !== 0);
    // Try with .quantity: this._buyPromises.filter((x) => x.quantity !== 0);
  }

  setLastModifiedBuyPromise(buyPromise: BuyPromise): void {
    this.lastModifiedBuyPromise = buyPromise;
  }

  /**
   * Get Last modified buy promise item in shopping cart.
   * @returns {BuyPromise}
   */
  getLastModifiedBuyPromise(): BuyPromise {
    return this.lastModifiedBuyPromise;
  }

  getCostingCeg(container: Container): Costing {
    return container.costings.find((costing) => costing.costing_type === 'ceg');
  }

  /**
   * Get the range by the selected quantity for product.
   * @param {product} Product to search range.
   * @returns{range} Range.
   */
   getRangeSelected(buyPromise: BuyPromise): Range {
    const costingCeg: Costing = this.getCostingCeg(buyPromise.product);
    return costingCeg.ranges.find(
      (range: Range) => (range.min_units <= buyPromise.quantity
        && (buyPromise.quantity <= range.max_units || !range.max_units))
    );
  }

  /**
   * Get net total for products in shopping cart.
   * @returns{netTotal} number.
   */
  getNetTotal() {
    let netTotal = 0;
    for (const bp of this.getNonZeroBuyPromises()) {
      const rangeSelected = this.getRangeSelected(bp);
      let net = 0;
      if (rangeSelected) {
        net = Number(rangeSelected.net);
        const unitPrice = net * bp.quantity;
        netTotal += unitPrice;
      }
    }
    return netTotal;
  }

  /**
   * Get total taxes for products in shopping cart.
   * @returns{totalTaxes} number.
   */
  getTotalTaxes() {
    let totalTaxes = 0;
    for (const bp of this.getNonZeroBuyPromises()) {
      const rangeSelected = this.getRangeSelected(bp);
      let tax = 0;
      if (rangeSelected) {
        tax = Number(rangeSelected.tax);
        const unitPrice = tax * bp.quantity;
        totalTaxes += unitPrice;
      }
    }
    return totalTaxes;
  }

  /**
   * Get total price for products in shopping cart.
   * @returns{total} number.
   */
  getTotalPrice() {
    return Number(this.getNetTotal()) + Number(this.getTotalTaxes());
  }

  getTotalPriceOfBuyPromise(buyPromise: BuyPromise) {
    const rangeSelected = this.getRangeSelected(buyPromise);
    let net = 0;
    let tax = 0;
    if (rangeSelected) {
      net = Number(rangeSelected.net);
      tax = Number(rangeSelected.tax);
    }
    return (net + tax) * buyPromise.quantity;
  }

  /**
   * Get [] of ids of different impo publications ids present in a list of buy promises.
   */
  getImpoPublicationsIds(buyPromises:BuyPromise[]): number[] {
    const impoPublicationsIds: number[] = [];
    for (const bp of buyPromises) {
      const publication_id = bp.publication_id ? bp.publication_id : bp.product.publication_impo
      if (impoPublicationsIds.indexOf(publication_id) === -1) {
        impoPublicationsIds.push(publication_id);
      }
    }
    return impoPublicationsIds;
  }
}
