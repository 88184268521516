import { Injectable } from '@angular/core';
import { Container } from '@app/models/container';
import { GoogleTagManagerService as gtmService } from 'angular-google-tag-manager';

export interface IGTMEvent {
  event: string;
  item_name?: string, 
  item_price?: number,
  item_published_product_id?: number;
  item_range?: string;
  item_category?: string;
  price?: number;
  currency?: string;
  quantity?: number;
}

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {

  constructor(private gtmService:gtmService) { }

  addGtmToDom() {
    this.gtmService.addGtmToDom();
  }

  registerAddTocartEvent( 
    event:IGTMEvent
  ) {
    this.gtmService.pushTag({
      event: 'add_to_cart',
      currency: event.currency,
      price: event.price,
      quantity: event.quantity,
      published_product_id: event.item_published_product_id,
      range: event.item_range,
      item_name: event.item_name,
      item_category: event.item_category,
    });
  }

  registerAddToFavoritesEvent(
    productId, productName, productCategory
  ) {
    this.gtmService.pushTag({
      event: 'add_to_favorites',
      product_id: productId,
      item_name: productName,
      item_category: productCategory,
    }); 
  }

  registerSearchEvent(
    searchTerm: string,
    searchSection
  ) {
    this.gtmService.pushTag({
      event: 'search',
      search_term: searchTerm,
      search_section: searchSection,
    });
  }

  registerConfirmCartEvent(price, numberOfProducts) {
    this.gtmService.pushTag({
      event: 'confirm_cart',
      quantity: numberOfProducts,
      currency: 'USD',
      price: price,
    });
  }

  /**
   * Register the event when the user is interested in a impo product.
   * At the moment is defined when the user clicks open the gallery modal and when scroll on the description.
   * @param container 
   */
  registerViewProductContent(container:Container) {
    this.gtmService.pushTag({
      event: 'view_product_content',
      item_name: container.product_name,
      item_category: container.category_name,
      impo_publication_code: container.publication_title,
    }); 
  }

  /**
   * Register the event when a impo product card is loaded on impo home page.
   * @param containers 
   */
  registerContainerLoadedEvent(containers:Container[]) {
    containers.forEach(container => {
        this.gtmService.pushTag({
          event: 'impo_loaded_item',
          impo_publication_code: container.publication_title,
        });
      }
    );
  }

  /**
   * Register the event when the user navigates to a category page.
   */
  registerCategoryPageViewEvent(categoryId:number, categoryName:string) {
    if (categoryId && categoryName) {
      this.gtmService.pushTag({
        event: 'category_page_view',
        category_id: categoryId,
        category_name: categoryName,
      });
    }
  }

  registerProspectionEvent(eventName, eventCategory, eventAction, eventLabel, eventValue) {
    this.registerOldAnalyticsEvent(eventName, eventCategory, eventAction, eventLabel, eventValue);
    this.gtmService.pushTag({
      event: 'home_partner_acquisition',
      step: eventLabel
    });
  }

  /**
   * Until we fully migrate to GoogleAnalytics 4, we need to register events for UniversalAnalytics.
   */
  registerOldAnalyticsEvent(eventName, eventCategory, eventAction, eventLabel, eventValue) {
    (<any>window).ga( 
      'send',
      eventName,
      eventCategory,
      eventAction,
      eventLabel,
      eventValue
    );
  }
}
