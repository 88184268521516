import { NgModule, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';

import { I18n } from '@ngx-translate/i18n-polyfill';
import { environment } from '@environments/environment';

const translations = require(`raw-loader!../../locale/messages.${environment.locale}.xlf`).default;

@NgModule({
  providers: [
    I18n,
    { provide: TRANSLATIONS, useValue: translations },
    { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' }
  ]
})
export class I18nModule {
}
