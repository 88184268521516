import { BuyPromise } from './buy-promise';

export class ShoppingCart {
  company_id: number;

  min_purchase: number;

  has_received_merchandise: boolean;

  can_draft_purchase_order: boolean;

  accepted_terms_and_conditions: boolean;

  captcha_token: string;

  items: BuyPromise[];

  draft_order_id?: number;
}

export class DraftOrder {
  company_id: number;

  captcha_token: string;

  items: BuyPromise[];
}

export class DPOConfirmation {
  has_any_unconfirmed: boolean;
}
