import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HotjarService {
  private _isStaffUser: boolean;

  constructor(@Inject('Window') private window: Window) { }

  set isStaffUser(isStaff: boolean) {
    this._isStaffUser = isStaff;
  }

  get isStaffUser(): boolean {
    return this._isStaffUser;
  }

  setTrigger(triggerName: string) {
    if (!this.isStaffUser) {
      (<any> this.window).hj('trigger', triggerName);
    }
  }

  setIdentity(id: number, data: Object) {
    if ((<any> this.window).hj) {
      (<any> this.window).hj('identify', id, data);
    }
  }

  setTriggerAccountStatusExperience() {
    this.setTrigger('account-status-experience');
  }

  setTriggerImputePaymentExperience() {
    this.setTrigger('impute-payment-experience');
  }

  setTriggerPaymentHistoryExperience() {
    this.setTrigger('payment-history-experience');
  }

  setTriggerPaymentProjectionExperience() {
    this.setTrigger('payment-projection-experience');
  }

  setTriggerProductStatusExperience() {
    this.setTrigger('product-status-experience');
  }

  setTriggerRegistration() {
    this.setTrigger('registration');
  }

  setTriggerSearchProduct() {
    this.setTrigger('search-product');
  }

  setTriggerTrackingOrderExperience() {
    this.setTrigger('tracking-order-experience');
  }

  setTriggerConfirmOrder() {
    this.setTrigger("confirm-order");
  }
}
