import { Component } from '@angular/core';
import { AuthService } from '@app/services/auth/auth.service';
import { countries } from '@app/global-settings.data';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from './services/google-tag-manager/google-tag-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(private authService: AuthService, translate: TranslateService,
              private gtmService: GoogleTagManagerService) {
    this.gtmService.addGtmToDom();
    authService.setSentryContext();

    // @ngx-translate/core configuration
    const countryCode = localStorage.getItem('country_code') || 'ARG';
    const locale = countries[countryCode];
    translate.setDefaultLang(locale);
  }
}
