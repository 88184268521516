import { HttpClient } from '@angular/common/http';
import { TranslateCompiler, TranslateLoader } from '@ngx-translate/core';

export const translateChildSettings = (defaultLanguage: string, useFactory: Function, compiler: any) => ({
  defaultLanguage,
  loader: {
    provide: TranslateLoader,
    useFactory,
    deps: [HttpClient]
  },
  compiler: {
    provide: TranslateCompiler,
    useClass: compiler
  },
  isolate: true
});
